import { createStore } from 'vuex';

export default createStore({
  state: {
    userData: null,
    allUsersData: [], 
    allClientsData: [], 
    allProjectsData: [],
    Selected_Option: null,
    Selected_Object: null,
    theme: 'dark', // Default theme,
    Client_DB: null,
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },

    Update_Selected_Option(state, Selected_Option) {
      state.Selected_Option = Selected_Option;
    },

    Update_Selected_Object(state, Selected_Object) {
      state.Selected_Object = Selected_Object;
    },

    Update_allUsersData(state, allUsersData) {
      state.allUsersData = allUsersData;
    },

    Update_allClientsData(state, allClientsData) {
      state.allClientsData = allClientsData;
    },

    Update_allProjectsData(state, allProjectsData) {
      state.allProjectsData = allProjectsData;
    },

    // Mutation to update the theme
    Update_Theme(state, newTheme) {
      state.theme = newTheme;
    },

    Update_Client_DB(state, newClient_DB) {
      state.Client_DB = newClient_DB;
    },

    //Client_DB
  },
  actions: {
    // Fetch user data after successful login
    async fetchUserData({ commit }) {
      try {
        const user = JSON.parse(localStorage.getItem('user')); // Get user data from localStorage
        if (user) {
          commit('setUserData', user);
          console.log('User data loaded:', user);
        } else {
          console.error('No user data found in localStorage');
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    },

    async fetchAllUsersData({ state, commit }) {
      try {
        // Replace with your API endpoint to fetch all users
        const response = await fetch(`/api/users?companyID=${state.userData?.companyID}`);
        const users = await response.json();
        commit('Update_allUsersData', users);
        console.log('Fetched all users data');
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    },

    async fetchAllClientsData({ state, commit }) {
      try {
        // Replace with your API endpoint to fetch all clients
        const response = await fetch(`/api/clients?companyID=${state.userData?.companyID}`);
        const clients = await response.json();
        commit('Update_allClientsData', clients);
        console.log('Fetched all clients data');
      } catch (error) {
        console.error('Error fetching clients data:', error);
      }
    },

    async fetchAllProjectsData({ state, commit }) {
      try {
        // Replace with your API endpoint to fetch all projects
        const response = await fetch(`/api/projects?companyID=${state.userData?.companyID}`);
        const projects = await response.json();
        commit('Update_allProjectsData', projects);
        console.log('Fetched all projects data');
      } catch (error) {
        console.error('Error fetching projects data:', error);
      }
    },

    Update_Selected_Option(context, newData) {
      context.commit('Update_Selected_Option', newData);
    },

    Update_Selected_Object(context, newData) {
      context.commit('Update_Selected_Object', newData);
    },

    Update_Theme(context, newData) {
      context.commit('Update_Theme', newData);
    },

    Update_Client_DB(context, newClient_DB) {
      context.commit('Update_Client_DB', newClient_DB);
    }

  },
  getters: {
    userData: (state) => state.userData,
    allUsersData: (state) => state.allUsersData,
    allClientsData: (state) => state.allClientsData,
    allProjectsData: (state) => state.allProjectsData,
    theme: (state) => state.theme ,
    Client_DB: (state) => state.Client_DB 
  },
});
