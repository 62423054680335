import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { 
    path: '/', 
    component: () => import('./components/page_overview.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/signin', 
    component: () => import('./components/main_sign_in.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/client-reset-password', 
    component: () => import('./components/main_sign_in_client_reset_password.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/client-reset-password-otp', 
    component: () => import('./components/main_sign_in_client_reset_password_otp.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/user-reset-password', 
    component: () => import('./components/main_sign_in_user_reset_password.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/user-reset-password-otp', 
    component: () => import('./components/main_sign_in_user_reset_password_otp.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/signinuser', 
    component: () => import('./components/main_sign_in_user.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/overview', 
    component: () => import('./components/page_overview.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/strategic-impact', 
    component: () => import('./components/page_strategic_impact_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/information', 
    component: () => import('./components/page_information.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/performance', 
    component: () => import('./components/page_performance.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/projects', 
    component: () => import('./components/page_projects_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/add/users', 
    component: () => import('./components/page_users_add_user_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/edit/users', 
    component: () => import('./components/page_users_edit_user_1.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/add/project', 
    component: () => import('./components/page_projects_add_project_1.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/edit/project', 
    component: () => import('./components/page_projects_edit_project_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/businesscase', 
    component: () => import('./components/page_business_case_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/businesscaseview', 
    component: () => import('./components/page_business_case_view_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/assessmentdocuments', 
    component: () => import('./components/page_assessment_documents.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/user-requirements', 
    component: () => import('./components/page_user_requirements_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/user-requirements-view', 
    component: () => import('./components/page_user_requirements_view_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/project-readiness', 
    component: () => import('./components/page_project_readiness_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/project-readiness-view', 
    component: () => import('./components/page_project_readiness_view_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/meeting-template', 
    component: () => import('./components/page_meeting_template_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/business-case-impact', 
    component: () => import('./components/page_business_case_impact.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/create-program', 
    component: () => import('./components/page_create_program_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/choose-assessment', 
    component: () => import('./components/page_choose_assesment.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/meeting-view', 
    component: () => import('./components/page_meeting_view.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/users', 
    component: () => import('./components/page_users.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/notifications', 
    component: () => import('./components/main_notifications.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/global-settings', 
    component: () => import('./components/page_global_settings.vue'),
    meta: { requiresAuth: false }
  },

  { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});


export default router;
